import React, { useState, Fragment } from "react"
import i18n from "i18next"
import { Link } from "gatsby"
import ExsisLogo from "../../../images/navBar/VersionMobileLogo.png"
import ExsisBirthday from "../../../images/navBar/logo30YearsExsisFit.png"
import ButtonGetInTouch from "./ButtonGetInTouch"

const NavBarMobile = ({ pathname }) => {
  const [hidden, setHidden] = useState("hidden")
  const [rotate, setRotate] = useState("")
  const [menuExperience, setMenuExperience] = useState(false)
  const [menuMedia, setMenuMedia] = useState(false)
  const [language, setLanguage] = useState(i18n.language)

  function effect() {
    hidden === "hidden" ? setHidden("") : setHidden("hidden")
    rotate === "collapsable-menu--rotate"
      ? setRotate("")
      : setRotate("collapsable-menu--rotate")
  }

  function closeNavbar() {
    setHidden("hidden")
    setRotate("")
    setMenuExperience(false)
    setMenuMedia(false)
  }

  function openSubmenu() {
    if (menuExperience == false) {
      setMenuExperience(true)
    }

    if (menuExperience == true) {
      setMenuExperience(false)
    }
  }

  function openSubmenuMedia() {
    if (menuMedia == false) {
      setMenuMedia(true)
    }
    if (menuMedia == true) {
      setMenuMedia(false)
    }
  }
  function handleClickLanguage(language) {
    closeNavbar()
    setLanguage(language)
    i18n.changeLanguage(language)
  }

  return (
    <Fragment>
      <header className="header-navbar--mobile navBarMobile">
        <div className="h-full">
          <Link to="/" onClick={closeNavbar}>
            <img
              className=" p-3 imgLogoMobile"
              src={ExsisBirthday}
              alt="Exsis logo"
            />
          </Link>
        </div>
        <div className="header-navbar__right-side--mobile">
          <div className="navigation" onClick={effect}>
            <span className={"navigation__icon " + rotate}>&nbsp;</span>
          </div>
        </div>
      </header>
      <div className={"collapsable-menu " + hidden}>
        <ul className="collapsable-menu__list-elements">
          <li
            className={
              pathname == "/"
                ? "collapsable-menu__elementSelect p-6 backShadow"
                : "collapsable-menu__element p-6"
            }
          >
            <Link to="/" onClick={closeNavbar}>
              {i18n.t("home.menu.seccion1")}
            </Link>
          </li>
          <li
            className={
              pathname == "/our-Experience" || pathname == "/exsis-academy"
                ? "backShadow collapsable-menu__element p-6"
                : "collapsable-menu__element p-6"
            }
          >
            <div className="flex" onClick={openSubmenu}>
              {i18n.language == "en" ? (
                <>
                  <p
                    className={
                      pathname == "/our-Experience" ||
                      pathname == "/exsis-academy"
                        ? "collapsable-menu__elementSelect "
                        : "collapsable-menu__element"
                    }
                  >
                    E
                  </p>
                  <p
                    className={
                      pathname == "/our-Experience" ||
                      pathname == "/exsis-academy"
                        ? "titleSectionNavBarBlueSelect"
                        : "titleSectionNavBarBlue"
                    }
                  >
                    x
                  </p>
                  <p
                    className={
                      pathname == "/our-Experience" ||
                      pathname == "/exsis-academy"
                        ? "collapsable-menu__elementSelect "
                        : "collapsable-menu__element"
                    }
                  >
                    perience{" "}
                  </p>
                </>
              ) : (
                <>
                  <p
                    className={
                      pathname == "/our-Experience" ||
                      pathname == "/exsis-academy"
                        ? "collapsable-menu__elementSelect "
                        : "collapsable-menu__element "
                    }
                  >
                    E
                  </p>
                  <p className="titleSectionNavBarBlue">x</p>
                  <p
                    className={
                      pathname == "/our-Experience" ||
                      pathname == "/exsis-academy"
                        ? "collapsable-menu__elementSelect"
                        : "collapsable-menu__element "
                    }
                  >
                    periencia{" "}
                  </p>
                </>
              )}
            </div>
          </li>

          {menuExperience == true ? (
            <>
              <li className="collapsable-menu__element p-6 font-avenir-medium text-14">
                <Link to="/our-Experience" onClick={closeNavbar}>
                  {i18n.t("home.menu.seccion8")}
                </Link>
              </li>

              <li className="collapsable-menu__element p-6 font-avenir-medium text-14">
                <Link to="/exsis-academy" onClick={closeNavbar}>
                  {i18n.t("home.menu.seccion9")}
                </Link>
              </li>
            </>
          ) : null}

          <li
            className={
              pathname == "/service-expansion"
                ? "collapsable-menu__elementSelect p-6 backShadow"
                : "collapsable-menu__element p-6"
            }
          >
            <Link to="/service-expansion" onClick={closeNavbar}>
              {i18n.t("home.menu.seccion2")}
            </Link>
          </li>
          <li
            className={
              pathname == "/exclusive-products" ||
              pathname == "/TalentATS" ||
              pathname == "/agile-team" ||
              pathname == "/transformation"
                ? "collapsable-menu__elementSelect p-6 backShadow"
                : "collapsable-menu__element p-6"
            }
          >
            <Link to="/exclusive-products" onClick={closeNavbar}>
              {i18n.t("home.menu.seccion3")}
            </Link>
          </li>
          <li
            className={
              pathname == "/exceptional-careers"
                ? "collapsable-menu__elementSelect p-6 backShadow"
                : "collapsable-menu__element p-6"
            }
          >
            <Link to="/exceptional-careers" onClick={closeNavbar}>
              {i18n.t("home.menu.seccion4")}
            </Link>
          </li>
          <li className="collapsable-menu__element p-6 font-avenir-medium text-14">
            <div to="/contact-us" onClick={openSubmenuMedia} className="flex">
              <p
                className={
                  pathname == "/media-blog" ||  pathname == "/x-media-success-stories" 
                    ? "titleSectionNavBarBlueSelect mr-2"
                    : "titleSectionNavBarBlue mr-2"
                }
              >
                X
              </p>
              <p
                className={
                  pathname == "/media-blog" || pathname == "/x-media-success-stories" 
                    ? "collapsable-menu__elementSelect "
                    : "collapsable-menu__element"
                }
              >
                Media{" "}
              </p>
            </div>
          </li>
          {menuMedia == true ? (
            <>
              <li className="collapsable-menu__element p-6 font-avenir-medium text-14">
                <Link to="/media-blog" onClick={closeNavbar}>
                  {i18n.t("home.menu.seccion5")}
                </Link>
              </li>

              <li className="collapsable-menu__element p-6 font-avenir-medium text-14">
                <Link to="/x-media-success-stories" onClick={closeNavbar}>
                  {i18n.t("home.menu.seccion6")}
                </Link>
              </li>
              {i18n.language == "es" ?
                <li className="collapsable-menu__element  p-6 ">
                <Link to="/x-media-podcast" onClick={closeNavbar}>
                  {i18n.t("home.menu.seccion7")}
                </Link>
              </li> :null
              }
             

            </>
          ) : null}
          <ButtonGetInTouch />
          <div className="p-6 ml-auto w-full borderBottom">
            <div className="buttomLanguage  float-right cursor-pointer flex">
              <button
                className={
                  language === "en"
                    ? "mr-6 menuMobileExsis languageMobile colorEx"
                    : "mr-6 menuMobileExsis languageMobile"
                }
                onClick={() => handleClickLanguage("en")}
              >
                EN
              </button>
              <button
                className={
                  language === "es"
                    ? "languageMobile colorEx"
                    : "languageMobile"
                }
                onClick={() => handleClickLanguage("es")}
              >
                ES
              </button>
            </div>
          </div>
        </ul>
      </div>
    </Fragment>
  )
}

export default NavBarMobile
